<template>
	<svg width="24" height="24" viewBox="-4 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M5.55369 2.2132C5.80916 2.04116 6.11914 1.96948 6.4242 2.01193C6.72926 2.05438 7.0079 2.20795 7.20669 2.4432L7.28869 2.5522L11.0387 8.1162C11.1912 8.34283 11.2652 8.61316 11.2495 8.88585C11.2338 9.15854 11.1292 9.41858 10.9517 9.6262L10.8547 9.7282L6.10469 14.1642C5.87104 14.3831 5.56227 14.5041 5.24208 14.502C4.92188 14.5 4.61468 14.3752 4.38382 14.1533C4.15295 13.9314 4.01604 13.6294 4.00132 13.3095C3.9866 12.9897 4.09519 12.6764 4.30469 12.4342L4.39869 12.3372L8.36869 8.6282L5.21569 3.9482C5.04394 3.69282 4.97243 3.38305 5.01487 3.07823C5.05731 2.7734 5.21071 2.49495 5.44569 2.2962L5.55369 2.2132Z"
			fill="currentColor"
		/>
	</svg>
</template>
